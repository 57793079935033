const LocalStorage = global.localStorage ? localStorage : null;
const VIEWED_PRODUCTS_KEY = 'recentlyViewed';
const VIEWED_PRODUCTS_LIMIT = 20;

export function getRecentlyViewedProductIds() {
  const str = LocalStorage?.getItem(VIEWED_PRODUCTS_KEY);
  return str ? JSON.parse(str) : [];
}
// The most recently viewed products go to beginning of the list
export function setRecentlyViewedProductId(id: number) {
  const list = getRecentlyViewedProductIds();
  const indexOfId = list.indexOf(id);
  if(indexOfId >= 0) {
    list.splice(indexOfId, 1);
  }
  list.unshift(id);
  if(list.length > VIEWED_PRODUCTS_LIMIT) {
    list.pop();
  }
  LocalStorage?.setItem(VIEWED_PRODUCTS_KEY, JSON.stringify(list));
}
