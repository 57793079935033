export const HELP_NAME_PAGE = "help";
export const DELIVERY_NAME_PAGE = "delivery";
export const PAYMENTS_NAME_PAGE = "payments";
export const WARRANTY_NAME_PAGE = "warranty";
export const PRIVACY_NAME_PAGE = "privacy";
export const TERMS_NAME_PAGE = "terms";
export const ABOUT_NAME_PAGE = "about";
export const CONTACTS_NAME_PAGE = "contacts";
export const NEWS_NAME_PAGE = "news";
export const JOB_NAME_PAGE = "job";
export const FAQ_NAME_PAGE = "faq";
export const BUY_IN_CREDIT_NAME_PAGE = "buy-in-credit";
export const PAYMENT_IN_PARTS_NAME_PAGE = "payment-in-parts";
export const GIFT_CARD_NAME_PAGE = "gift-card";

export const baseRoute = "";
const basePagesRoute = `${baseRoute}/pages`

const routesHome = {
  home: () => `${baseRoute}/`,
  staticPage: () => `${basePagesRoute}/`,
  delivery: () => `${basePagesRoute}/${DELIVERY_NAME_PAGE}/`,
  privacy: () => `${basePagesRoute}/${PRIVACY_NAME_PAGE}/`,
  payment: () => `${basePagesRoute}/${PAYMENTS_NAME_PAGE}/`,
  warranty: () => `${basePagesRoute}/${WARRANTY_NAME_PAGE}/`,
  term: () => `${basePagesRoute}/${TERMS_NAME_PAGE}/`,
  about: () => `${basePagesRoute}/${ABOUT_NAME_PAGE}/`,
  contacts: () => `${basePagesRoute}/${CONTACTS_NAME_PAGE}/`,
  news: () => `${basePagesRoute}/${NEWS_NAME_PAGE}/`,
  job: () => `${basePagesRoute}/${JOB_NAME_PAGE}/`,
  faq: () => `${basePagesRoute}/${FAQ_NAME_PAGE}/`,
  buyInCredit: () => `${basePagesRoute}/${BUY_IN_CREDIT_NAME_PAGE}/`,
  paymentInParts: () => `${basePagesRoute}/${PAYMENT_IN_PARTS_NAME_PAGE}/`,
  giftCard: () => `${basePagesRoute}/${GIFT_CARD_NAME_PAGE}/`,
  permissionDenied: () => `${baseRoute}/permission-denied/`,
  help: () => `${baseRoute}/${HELP_NAME_PAGE}/`,
  helpCategory: (category: string, section?: string) => `${baseRoute}/help/${category}/${section ? `${section}/` : ""}`,
};

export default routesHome;
