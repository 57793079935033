export const baseRoute = process.env.NODE_ENV === "production" ? `app` : 'seller/app';
export const baseRouteStaticPages = process.env.NODE_ENV === "production" ? `` : '/seller';

export const SELLER_HELP_NAME_PAGE = "seller-help";
export const SELLER_PRIVACY_NAME_PAGE = "seller-privacy";
export const SELLER_TERMS_NAME_PAGE = "seller-terms";
export const SELLER_RULES_NAME_PAGE = "seller-rules";

const routesSeller = {
  landing: () => process.env.NODE_ENV === "production" ? `${global.__env?.DOMAINE_SELLER}` : '/seller',
  kyc: () => `/${baseRoute}/`,
  dashboard: () => `/${baseRoute}/dashboard/`,
  store: () => `/${baseRoute}/store/`,
  stores: () => `/${baseRoute}/stores/`,
  products: () => `/${baseRoute}/products/`,
  // tab = 1 - tab product-properties
  product: (productId: any, tab?: number) =>
    `/${baseRoute}/products/product/?id=${productId}${tab ? `&tab=${1}` : ''}`,
  addProduct: () => `/${baseRoute}/products/add-product/`,
  productVariations: (productId: any) =>
    `/${baseRoute}/products/variations/?id=${productId}`,
  gallery: () => `/${baseRoute}/gallery/`,
  importCSV: () => `/${baseRoute}/products/import-csv/`,
  importXML: () => `/${baseRoute}/products/import-xml/`,
  apiKey: () => `/${baseRoute}/products/api-key/`,
  orders: () => `/${baseRoute}/orders/`,
  order: (orderId: any) => `/${baseRoute}/order/?id=${orderId}`,
  reviews: () => `/${baseRoute}/reviews/`,
  notifications: () => `/${baseRoute}/notifications/`,
  settings: () => `/${baseRoute}/settings/`,
  settingsGeneral: () => `/${baseRoute}/settings/general/`,
  settingsAddresses: () => `/${baseRoute}/settings/adresses/`,
  settingsDelivery: () => `/${baseRoute}/settings/delivery/`,
  settingsAccount: () => `/${baseRoute}/settings/account/`,
  settingsKYBProfile: () => `/${baseRoute}/settings/kyb-profile/`,
  support: () => `/${baseRoute}/support/`,
  media: () => `/${baseRoute}/media/`,
  payments: () => `/${baseRoute}/finance/payments/`,
  invoices: () => `/${baseRoute}/finance/invoices/`,
  files: (tab?: string) => `/${baseRoute}/files/?tab=${tab}`,
  file: (fileId: any, fileType: any) =>
    `/${baseRoute}/files/file/?id=${fileId}&type=${fileType}`,

  help: () => `${baseRouteStaticPages}/help/`,
  terms: () => `${baseRouteStaticPages}/terms/`,
  privacy: () => `${baseRouteStaticPages}/privacy/`,
  rules: () => `${baseRouteStaticPages}/rules/`,
};

export default routesSeller;
