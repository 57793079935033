export const parseEnvInt = (value: string | undefined, defaultValue: number) => {
  if (!value) {
    return defaultValue;
  }

  try {
    const val = parseInt(`${value}`, 10);
    return !Number.isNaN(val) ? val : defaultValue;
  } catch (err) {
    console.log("Error parse number value fron env");
    return defaultValue;
  }
};

export const getPictureURL = (picture: any, thumbnail = false) =>
  typeof picture === "object" ? (thumbnail ? picture.thumbnail : picture.file) : picture;

export const deepCopyObject = (obj: any = {}) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (err) {
    return Array.isArray(obj) ? [] : {};
  }
};

export const idsToString = (arr?: any[]) => arr ? arr?.map((id: any) => `${id}`) : [];

export const idsToNumber = (arr?: any[]) => arr ? arr.map((id: any) => (typeof id === "string" ? parseInt(id) : id)) : [];

export const getIds = (arr: any[] = [], asString?: boolean) =>
  arr.map((item: any) => (asString ? `${item.id}` : item.id));

export const prepareDateFromISOString = (dateStr?: string) => {
  // 2022-08-30T07:24:05.315870Z OR 2022-08-30T07:24:00Z => 2022-08-30T07:24

  if (!dateStr) {
    return "";
  }

  const date = new Date(`${dateStr}`);
  const zone = date.getTimezoneOffset();

  date.setTime(date.getTime() - zone * 60 * 1000);

  try {
    return `${date.toISOString()}`.substring(0, 16);
  } catch (err) {
    console.error("prepareDateFromISOString", err, dateStr);
    return "";
  }
};

export const prepareDateToISOString = (dateStr?: string) => {
  if (!dateStr) {
    return;
  }

  return new Date(dateStr).toJSON();
};

export const getDateFields = (dateStr?: string) => {
  const date = new Date(`${dateStr}`);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return {
    year,
    month: month > 9 ? month : `0${month}`,
    day: day > 9 ? day : `0${day}`,
    hours: hours > 9 ? hours : `0${hours}`,
    minutes: minutes > 9 ? minutes : `0${minutes}`,
  };
};

export const removeEmptyItems = (arr?: any[]) => arr?.filter((item) => !!item) || [];

export const hashCode = (str: string): number => {
  return Array.from(str).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);
};

export const hashObject = (obj: any, ignore: any[] = [undefined]): string => {
  if (obj === null) {
    return "null";
  }

  if (typeof obj === "string") {
    return obj;
  }

  if (typeof obj === "number") {
    return `${obj}`;
  }

  if (typeof obj === "object") {
    try {
      const t: any = {};

      Object.keys(obj).forEach((key) => {
        const v = obj[key];
        if (!ignore.includes(v)) {
          t[key] = v;
        }
      }, []);

      return `${hashCode(JSON.stringify(t))}`;
    } catch (err) {
      console.error("hashObject", obj, err);
    }
  }

  return `${obj}`;
};

export function uniqueList(list: { id: number }[]) {
  const uniqueIds = [...new Set(list.map((i) => i.id))];
  const result: any[] = [];
  uniqueIds.forEach((id) => {
    result.push(list.find((i) => i.id == id));
  });
  return result;
}